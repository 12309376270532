<template>
  <a-modal
    :title="!rowId ? '添加签署公章' : '添加/替换手写签名图片'"
    v-if="visible"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel"
  >
    <a-row>
      <a-col :span="24" style="margin-bottom: 24px">
        <a-upload :file-list="fileList" :before-upload="beforeUpload" accept="image/*">
          <a-button> <a-icon type="upload" />选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :md="12">
        <p class="note">原始图片处理：</p>
        <template v-if="!ie">
            <vue-cropper
              :style="{ height: !rowId ? '350px' : '150px' }"
              ref="cropper"
              :img="options.img"
              :info="true"
              outputType="png"
              :autoCrop="options.autoCrop"
              :autoCropWidth="!rowId ? options.autoCropWidth : 100"
              :autoCropHeight="!rowId ? options.autoCropHeight : 50"
              :enlarge="3"
              :fixedBox="options.fixedBox"
              @realTime="realTime"
              full
            >
            </vue-cropper>
        </template>
        
        <div v-else>
          <VueCropper
            style="height: 350px"
            ref="cropper"
            v-if="options.img"
            :src="options.img"
            @crop="tests"
          >
          </VueCropper>
        </div>
        
      </a-col>
      <a-col :xs="24" :md="12" class="Imag" v-if="!ie">
        <div>
          <p class="note">去底图片：</p>
        <VueImageHandler
         :style="{ height: !rowId ? '350px' : '150px' }"
          v-if="previews.url"
          ref="vueImageHandler"
          :canvas-width="!rowId ? '200px' : '120px'"
          :canvas-height="!rowId ? '200px' : '60px'"
          :img-file="previews.url"
          :wipe-color="test ? '' : 'white'"
          :color-diff="colorDiff"
        />
        </div>
        
      </a-col>
    </a-row>
    <a-row>
      <a-col class="hint" :span="24" v-if="!ie">
        <span>提示：请拖动滚动条将图片白色背景部分调至透明</span>
      </a-col>
      <a-col :span="24" v-if="!ie">
        <span class="fl">容差值：</span>
        <a-slider class="slider" v-model="colorDiff" :max="400"/>
      </a-col>
      <a-col :lg="2" :md="2"> </a-col>
      <a-col :lg="{ span: 1, offset: 2 }" :md="2">
        <a-button icon="plus" @click="changeScale(1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="minus" @click="changeScale(-1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="undo" @click="rotateLeft" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="redo" @click="rotateRight" />
      </a-col>
      <a-col :lg="{ span: 2, offset: 6 }" :md="2">
        <a-button type="primary" @click="finish()" style="width: 120px" :loading="loading"
          >保存</a-button
        >
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import 'cropperjs/dist/cropper.css';
  // import VueCropper from 'vue-cropperjs';

import { uploadPhoto } from '../../../api/utils'
import { uploadseal } from '@/api/buyerCenter'
import { uploadsealSup } from '@/api/seller'
// import { VueImageHandler } from 'vue-image-handler/lib/vue-image-handler.umd.min.js'
let VueImageHandler = {}
let VueCropper = {}
const ie = !!window.ActiveXObject || "ActiveXObject" in window
if(ie) {
  // require('cropperjs/dist/cropper.css');
  VueCropper = require('vue-cropperjs/dist/VueCropper.js').default
  console.log(999999,VueCropper);
} else {
  VueImageHandler = require('vue-image-handler/lib/vue-image-handler.umd.min.js').VueImageHandler
  VueCropper = require('vue-cropper/dist/index.js').VueCropper
}
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    }
  },
  components: {
    VueCropper,
    VueImageHandler
  },
  data() {
    return {
      ie,
      visible: false,
      id: null,
      rowId: null,
      confirmLoading: false,
      fileList: [
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
        },
      ],
      uploading: false,
      options: {
        img: '',
        autoCrop: true,
        autoCropWidth: 170,
        autoCropHeight: 170,
        fixedBox: true,
      },
      previews: {
        url: ''
      },
      loading: false,
      test: false,
      colorDiff: 120,
      testss: false
    }
  },
  watch: {
    'previews.url'() {
      this.test = true
      this.$nextTick(() => {
        this.test = false
      })
    }
  },
  methods: {
    beforeUpload (file) {
      if (file.type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        return
      }
      console.log(file);
      this.fileList = [file];
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      this.previews.url = file
      return false
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    // bs64转File
    dataURLtoFile(dataurl, fileName) {
      if (!dataurl) return
      dataurl = dataurl.replace(/\n/g, '')
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    edit(id,type) {
      this.visible = true
      this.id = id
      this.type = type //判断卖家和买家
      /* 获取原始头像 */
    },
    close() {
      this.id = null
      this.visible = false
      this.options.img = ''
      this.$refs.vueImageHandler.clear()
      this.previews.url=''
    },
    cancelHandel() {
      this.close()
      this.$refs.vueImageHandler.clear()
    },
    changeScale(num) {
      num = num || 1
      if (this.ie) {
        this.$refs.cropper.relativeZoom(num);
      } else {
        this.$refs.cropper.changeScale(num)
        this.$refs.cropper.getCropData((data) => {
        this.previews.url = data
        })
      }
      
    },
    rotateLeft() {
      if (this.ie) {
        this.$refs.cropper.rotate(45);
      } else {
        this.$refs.cropper.rotateLeft()
        this.$refs.vueImageHandler.rotate()
      }
    },
    rotateRight() {
      if (this.ie) {
        this.$refs.cropper.rotate(-45);
      } else {
        this.$refs.cropper.rotateRight()
      }
    },

    // 上传图片（点击上传按钮）
     finish() {
      // 输出
      this.loading = true
      if(navigator.userAgent.toLowerCase().indexOf('trident')>-1) {
        uploadPhoto(this.dataURLtoFile(this.previews.url, 'seal.png'), async (obj) => {
          if (this.rowId) {
            this.loading = false      
            this.visible = false
            this.$emit('getImageUrl', {signatureUrl: obj, singerId: this.rowId})
            return
          }
          
          let res;
          //如果是买家 否则 卖家
          if(this.type=='buyer'){
            res = await uploadseal({
              purchaserId: this.$route.query.entityId,
              sealUrl: obj
            })
          }else{
            res = await uploadsealSup({
              supplierId: this.$route.query.entityId,
              sealUrl: obj
            })
          }
          this.loading = false      
          if (res.code === 200) {
            this.$notification.success({
              message: '成功',
              description: '添加成功！'
            })
            // this.$refs.vueImageHandler.clear()
            this.visible = false
            this.$emit('getSealList')
          } else {
            this.$notification.error({
              message: '错误',
              description: res.message
            })
          }
        })
        return
      } 
      this.$refs.vueImageHandler.getImageUrl((url) => {
        uploadPhoto(this.dataURLtoFile(url, 'seal.png'), async (obj) => {
          if (this.rowId) {
            this.$emit('getImageUrl', {signatureUrl: obj, singerId: this.rowId})
            this.loading = false      
            this.visible = false
            return
          }

          let res;
          //如果是买家 否则 卖家
          if(this.type=='buyer'){
            res = await uploadseal({
              purchaserId: this.$route.query.entityId,
              sealUrl: obj
            })
          }else{
            res = await uploadsealSup({
              supplierId: this.$route.query.entityId,
              sealUrl: obj
            })
          }

          this.loading = false      
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '添加成功！'
              })
              this.$refs.vueImageHandler.clear()
              this.visible = false
              this.$emit('getSealList')
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
        })
      })
      this.loading = true
    },
    realTime(data) {
      data.w &&
        this.$refs.cropper.getCropData((datas) => {
          this.previews.url = datas
        })
    },
    tests() {
      this.previews.url = this.$refs.cropper ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 170px;
  height: 170px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
.Imag {
  display: flex;
  align-items:center;
  justify-content: center;
}
/deep/.container {
  position: relative;
  .crop-wrapper {
    position: absolute;
    z-index: -1;
  }
}
.note {
  font-weight: 700;
  font-size: 15px;
}
.fl {
  float: left;
  line-height: 38px;
  color: #04182b;
  font-weight: 700;
  font-size: 15px;
}
.hint {
  color: #04182b;
  font-weight: 700;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 15px;
}
.slider {
  margin-left: 56px;
}
/deep/ .ant-upload-list-item-card-actions {
  display: none;
}
/deep/ .ant-modal-close-x {
  font-size: 20px;
  font-weight: 700;
}
/deep/ .ant-modal-title {
  
    font-size: 19px;

}
</style>
