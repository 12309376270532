<template>
  <div >
    <p class="mes-title">
      自2023年1月1日起，订单五流存证内容参照以下标准，请依据要求进行补充完善！
    </p>
    <dl class="mes-list">
      <dt>信息流</dt>
      <dd>
        <ul>
          <li>1、买家卖家入驻需要提供法人身份信息</li>
          <li>2、卖家申请工商注册时需要进行视频双录确保对注册授权的知情权</li>
          <li>
            3、运营方对随机抽查订单，电话回访卖家了解近期订单信息并进行录音
          </li>
        </ul>
      </dd>

      <dt>合同流</dt>
      <dd>
        <ul>
          <li>1、买卖双方合同签署，电子合同或线下合同均可</li>
          <li>2、合同需要体现双方信息、商品信息、交付周期等</li>
          <li>3、合同上需要有买方经手人、卖方经营者签名，以及买方合同章</li>
        </ul>
      </dd>

      <dt>资金流</dt>
      <dd>
        <ul>
          <li>1、需要提供反应资金支付情况的文件，支持线上或线下支付方式</li>
          <li>2、收款方必需为卖方公户，或卖方经营者私户</li>
          <li>3、累计收款金额需与订单总额一致，税款可预缴支付给第三方</li>
        </ul>
      </dd>

      <dt>货流</dt>
      <dd>
        <ul>
          <li>1、交通工具的具体信息：类型、起点、终点、运输时间段、运输吨数</li>
          <li>2、船运、火车运输需要补充运输发票，可以不提供司机信息</li>
          <li>
            3、三轮、农用车辆运输距离不超过30公里，需提供司机信息、过磅及磅单照
          </li>
          <li>
            4、货车需要补充司机信息，车牌、过磅、磅单、质检照片
            <ul>
              <li>
                1)运输距离超过100km，需要有中交轨迹、货运助手位置打卡或ETC发票
              </li>
              <li>2)运输距离超过500km，必需提供运输发票、ETC发票</li>
            </ul>
          </li>
        </ul>
      </dd>

      <dt>票流</dt>
      <dd>
        <ul>
          <li>需要提供订单对应的销售发票</li>
        </ul>
      </dd>
    </dl>
    <p class="row-time">2023年1月1日</p>
  </div>
</template>
<script>
export default {
  props:{
    isVisible :{
      type:Boolean,
      require:true
    }
  }
}
</script>
<style lang="less" scoped>
.row-time{
  text-align: right;
}
.mes-title{
  font-size: 14px;
  font-weight: bold;
}
.mes-list{
  font-size: 14px;
  dt{
    font-weight: bold;
    margin-top: 15px;
    position: relative;
    padding-left: 12px;
    &::before{
      content: '';
      height: 6px;
      width: 6px;
      background-color: #333;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -3px;
    }
  }
  li{
    text-indent: 2em;
    li{
      text-indent: 4em
    }
  }
}
</style>